import { Air } from '@air/next-icons';
import { useBreakpointsContext } from '@air/provider-media-query';
import VisuallyHidden from '@reach/visually-hidden';
import classNames from 'classnames';
import { isString } from 'lodash';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { NextSeo, NextSeoProps } from 'next-seo';
import { ReactNode } from 'react';

import { WorkspaceAvatarAndName } from '~/components/WorkspaceAvatarAndName';
import { Routes } from '~/constants/routes';
import { AUTH_LAYOUT_HEADER } from '~/constants/testIDs';
import { useShowIntercomOnMount } from '~/hooks/useShowIntercomOnMount';

export interface AuthLayoutProps {
  children?: ReactNode;
  layout?: {
    visibleTitleText?: string;
    visibleSubtitle?: ReactNode;
    workspaceInfo?: { name: string; avatarImageSource: string } | 'loading';
    nextSeoProps?: NextSeoProps;
    logoHref?: string;
    visibleSubtitleClassNames?: string;
    maxTextWidth?: number;
    testimonial?: ReactNode;
    extraContent?: ReactNode;
    mainClassName?: string;
  };
  banner?: ReactNode;
  className?: string;
}

const LogoLink = (props: LinkProps & { className?: string }) => (
  <Link {...props}>
    <VisuallyHidden>Go back to landing page.</VisuallyHidden>
    <Air className="w-[76px] fill-blue-9" aria-hidden="true" />
  </Link>
);

const formWidth = 420;

export const AuthLayout = ({ children, layout = {}, banner, className }: AuthLayoutProps) => {
  const { isAboveSmallScreen } = useBreakpointsContext();
  const { pathname } = useRouter();
  useShowIntercomOnMount(![Routes.auth.logout, Routes.auth.oauth].includes(pathname) && isAboveSmallScreen);

  const {
    logoHref = Routes.root,
    nextSeoProps,
    visibleTitleText,
    visibleSubtitle,
    workspaceInfo,
    mainClassName,
    visibleSubtitleClassNames,
    maxTextWidth = formWidth,
    testimonial,
    extraContent,
  } = layout;

  return (
    <>
      <NextSeo {...nextSeoProps} />

      <div className={classNames('h-screen', className)}>
        <div className="flex flex-col md:min-h-screen md:flex-row">
          <div className="flex-1">
            {!workspaceInfo ? (
              <header className="flex h-[70px] w-full items-center justify-between border-t-2 border-teal-7">
                <LogoLink className="ml-6 md:ml-8" href={logoHref} />
              </header>
            ) : (
              <header className="w-full border-b border-t-2 border-b-grey-5 border-t-teal-7">
                <div className="mx-4 grid h-[72px] grid-cols-1 md:grid-cols-3">
                  {workspaceInfo !== 'loading' ? (
                    <WorkspaceAvatarAndName
                      name={workspaceInfo.name}
                      image={workspaceInfo.avatarImageSource}
                      size="large"
                      className="mr-0 md:mr-5"
                    />
                  ) : (
                    // add empty box to prevent logo jumping when avatar is loading
                    <div />
                  )}

                  <LogoLink className="justify-self-center" href={logoHref} />
                </div>
              </header>
            )}

            {!!children && (
              <main className={classNames('mt-12 px-5 pb-20', mainClassName)}>
                {banner}
                {(!!visibleTitleText || !!visibleSubtitle) && (
                  <div className="mx-auto text-left" style={{ maxWidth: maxTextWidth }}>
                    {!!visibleTitleText && (
                      <h1
                        className={classNames(
                          'font-gelica text-center text-32 font-semibold tracking-[.03em] text-grey-12 sm:text-40 sm:tracking-[.03em]',
                          visibleSubtitle ? 'mb-1.5 md:mb-3' : 'mb-8 md:mb-10',
                        )}
                        data-testid={AUTH_LAYOUT_HEADER}
                      >
                        {visibleTitleText}
                      </h1>
                    )}

                    {isString(visibleSubtitle) ? (
                      <p className={classNames('mb-7 text-grey-11', visibleSubtitleClassNames)}>{visibleSubtitle}</p>
                    ) : (
                      visibleSubtitle
                    )}
                  </div>
                )}

                <div className="mx-auto" style={{ maxWidth: formWidth }}>
                  {children}
                </div>
              </main>
            )}
          </div>
          {testimonial}
          {extraContent}
        </div>
      </div>
    </>
  );
};
